// src/comps/login/Login.js

import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom'; // Импорт Link
import './Login.css';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    const params = new URLSearchParams();
    params.append('username', username);
    params.append('password', password);

    try {
      const response = await axios.post('https://heliosai.ru/api/token', params, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      });

      // Сохраняем токен и имя пользователя в localStorage
      localStorage.setItem('token', response.data.access_token);
      localStorage.setItem('username', username);

      // Перенаправляем на панель управления
      window.location.href = '/dashboard';
    } catch (error) {
      console.error('Ошибка при входе:', error);
      if (error.response && error.response.data && error.response.data.detail) {
        setErrorMessage(error.response.data.detail);
      } else {
        setErrorMessage('Ошибка при входе. Попробуйте снова.');
      }
    }
  };

  return (
    <div className="login-container">
      <form className="login-form" onSubmit={handleSubmit}>
        <h2>Войти</h2>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        <div>
          <label>Имя пользователя:</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Пароль:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit">Войти</button>
        <p className="register-link">
          Нет аккаунта? <Link to="/register">Зарегистрироваться</Link>
        </p>
      </form>
    </div>
  );
};

export default Login;
