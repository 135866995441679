// src/comps/dashboard/Dashboard.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './dashboard.css';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
  const [data, setData] = useState({
    domen: '',
    instagram_login: '',
    instagram_password: '',
    vk: '',
    whatsapp: '',
    telegram: '',
  });

  const [editData, setEditData] = useState({
    website_link: '',
    instagram_login: '',
    instagram_password: '',
    vk: '',
    whatsapp: '',
    telegram: '',
  });

  const [groupToken, setGroupToken] = useState('');
  const [visibleSection, setVisibleSection] = useState(null);
  const [loading, setLoading] = useState(true); // Состояние загрузки
  const [errorMessage, setErrorMessage] = useState(''); // Сообщение об ошибке

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        navigate('/login');
        return;
      }

      try {
        const response = await axios.get('https://heliosai.ru/api/get_client_data', {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        setData(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Ошибка при получении данных:', error);
        setLoading(false);
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('token');
          navigate('/login');
        } else {
          setErrorMessage('Ошибка при загрузке данных. Попробуйте позже.');
        }
      }
    };

    fetchData();
  }, [navigate]);

  useEffect(() => {
    const fetchGroupToken = async () => {
      const token = localStorage.getItem('token');
      const username = localStorage.getItem('username');
      if (!token || !username) return;
      try {
        const response = await axios.get(`https://heliosai.ru/api/group_token?login=${username}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        setGroupToken(response.data.group_token);
      } catch (error) {
        console.error('Ошибка получения group token:', error);
      }
    };

    fetchGroupToken();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditData({ ...editData, [name]: value });
  };

  const handleUpdate = async (field) => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
      return;
    }

    try {
      const response = await axios.post(
        `https://heliosai.ru/api/update_${field}`,
        { value: editData[field] },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        }
      );

      if (response.data.status === 'success') {
        setData({ ...data, [field]: editData[field] });
        alert('Данные успешно обновлены');
      } else {
        alert('Ошибка обновления данных');
      }
    } catch (error) {
      console.error('Ошибка обновления данных:', error);
      alert('Ошибка обновления данных. Попробуйте позже.');
    }
  };

  const handleUpdateInstagram = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
      return;
    }

    try {
      const response = await axios.post(
        `https://heliosai.ru/api/update_instagram`,
        {
          instagram_login: editData.instagram_login,
          instagram_password: editData.instagram_password,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        }
      );

      if (response.data.status === 'success') {
        setData({
          ...data,
          instagram_login: editData.instagram_login,
          instagram_password: editData.instagram_password,
        });
        alert('Данные Instagram успешно обновлены');
      } else {
        alert('Ошибка обновления данных');
      }
    } catch (error) {
      console.error('Ошибка обновления данных:', error);
      alert('Ошибка обновления данных. Попробуйте позже.');
    }
  };

  const handleUpdateWebsiteDomain = async () => {
    try {
      const url = new URL(editData.website_link);
      const domain = url.hostname.replace('www.', '');

      const token = localStorage.getItem('token');
      if (!token) {
        navigate('/login');
        return;
      }

      const response = await axios.post(
        `https://heliosai.ru/api/update_domain`,
        {
          value: domain,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        }
      );

      if (response.data.status === 'success') {
        setData({ ...data, domen: domain });
        alert('Домен успешно обновлен');
      } else {
        alert('Ошибка обновления домена');
      }
    } catch (error) {
      alert('Некорректная ссылка');
      console.error('Ошибка при извлечении домена:', error);
    }
  };

  const toggleSection = (section) => {
    setVisibleSection(visibleSection === section ? null : section);
  };

  if (loading) {
    return <p>Загрузка...</p>;
  }

  if (errorMessage) {
    return <p>{errorMessage}</p>;
  }

  return (
    <div className="dashboard-container">
      <h2 className="dashboard-title">
        Добро пожаловать в админ панель, {localStorage.getItem('username')}
      </h2>

      {/* Интеграция с сайтом */}
      <div className="integration-section">
        <h3 className="section-title">Интеграция с сайтом</h3>
        <p className="dashboard-text">
          Для интеграции на ваш сайт вставьте следующий код:
        </p>
        <div className="code-block">
          <input
            type="text"
            value={`<script src="https://heliosai.ru/widget/widget.js" data-sitekey="${data.domen}"></script>`}
            readOnly
            className="code-input"
            onClick={(e) => e.target.select()}
          />
        </div>

        <label htmlFor="website_link" className="dashboard-text">
          Введите ссылку на ваш сайт:
        </label>
        <input
          type="text"
          id="website_link"
          name="website_link"
          value={editData.website_link}
          onChange={handleChange}
          placeholder={data.domen ? `https://${data.domen}` : 'https://example.com'}
          className="input-field"
        />
        <button onClick={handleUpdateWebsiteDomain} className="button-save">
          Сохранить домен
        </button>

        <button
          className="button-link"
          onClick={() => (window.location.href = '/clients/site')}
        >
          Перейти к диалогам на сайте
        </button>
      </div>

      {/* ВКонтакте */}
      <div className="vk-section">
        <h3 className="section-title">ВКонтакте</h3>
        <label htmlFor="vk" className="dashboard-text">
          Введите API-ключ сообщества ВКонтакте:
        </label>
        <input
          type="text"
          id="vk"
          name="vk"
          value={editData.vk}
          onChange={handleChange}
          placeholder={data.vk || 'Ваш API-ключ ВКонтакте'}
          className="input-field"
        />
        <button onClick={() => handleUpdate('vk')} className="button-save">
          Изменить
        </button>
        <button className="button-toggle" onClick={() => toggleSection('vk')}>
          {visibleSection === 'vk' ? 'Скрыть' : 'Показать'} инструкцию
        </button>
        {visibleSection === 'vk' && (
          <div className="instruction-box">
            <p>Чтобы получить API-ключ сообщества ВКонтакте:</p>
            <ol>
              <li>Зайдите в управление вашим сообществом.</li>
              <li>Перейдите в раздел «Работа с API».</li>
              <li>Нажмите на кнопку «Создать ключ».</li>
            </ol>
            <p>
              После создания ключа, скопируйте его и вставьте в поле выше.
            </p>
          </div>
        )}
        <button
          className="button-link"
          onClick={() => (window.location.href = '/clients/vk')}
        >
          Перейти к ВКонтакте
        </button>
      </div>

      {/* Telegram */}
      <div className="telegram-section">
        <h3 className="section-title">Telegram</h3>
        <label htmlFor="telegram" className="dashboard-text">
          Введите токен вашего бота Telegram:
        </label>
        <input
          type="text"
          id="telegram"
          name="telegram"
          value={editData.telegram}
          onChange={handleChange}
          placeholder={data.telegram || 'Ваш токен бота'}
          className="input-field"
        />
        <button onClick={() => handleUpdate('telegram')} className="button-save">
          Изменить
        </button>
        <button className="button-toggle" onClick={() => toggleSection('telegram')}>
          {visibleSection === 'telegram' ? 'Скрыть' : 'Показать'} инструкцию
        </button>
        {visibleSection === 'telegram' && (
          <div className="instruction-box">
            <p>
              Чтобы интегрировать Telegram, создайте бота и получите токен
              доступа. Для этого:
            </p>
            <ol>
              <li>
                Откройте приложение Telegram и найдите <strong>@BotFather</strong>.
              </li>
              <li>
                Отправьте команду <code>/newbot</code> и следуйте инструкциям для
                создания нового бота.
              </li>
              <li>
                После создания бота вы получите токен доступа. Скопируйте его и
                вставьте в поле выше.
              </li>
            </ol>
          </div>
        )}
        <button
          className="button-link"
          onClick={() => (window.location.href = '/clients/telegram')}
        >
          Перейти к Telegram
        </button>
      </div>

      {/* WhatsApp */}
      <div className="whatsapp-section">
        <h3 className="section-title">WhatsApp</h3>
        <label htmlFor="whatsapp" className="dashboard-text">
          Введите токен из Whapi.cloud:
        </label>
        <input
          type="text"
          id="whatsapp"
          name="whatsapp"
          value={editData.whatsapp}
          onChange={handleChange}
          placeholder={data.whatsapp || 'Ваш токен Whapi.cloud'}
          className="input-field"
        />
        <button onClick={() => handleUpdate('whatsapp')} className="button-save">
          Изменить
        </button>
        <button className="button-toggle" onClick={() => toggleSection('whatsapp')}>
          {visibleSection === 'whatsapp' ? 'Скрыть' : 'Показать'} инструкцию
        </button>
        {visibleSection === 'whatsapp' && (
          <div className="instruction-box">
            <p>Для интеграции с WhatsApp через сервис Whapi.cloud:</p>
            <ol>
              <li>
                <a
                  href="https://whapi.cloud"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Зарегистрируйтесь на Whapi.cloud
                </a>{' '}
                и создайте канал.
              </li>
              <li>
                Вы получите 5 дней бесплатного использования, затем подписка
                составит 600 рублей в месяц.
              </li>
              <li>Создайте канал и скопируйте полученный токен.</li>
              <li>
                Перейдите в настройки в правом верхнем углу и вставьте токен в
                поле выше.
              </li>
              <li>
                Настройте вебхуки, используя следующую ссылку:
              </li>
            </ol>
            <div className="code-block">
              <input
                type="text"
                value={`https://heliosai.ru/webhook/${data.domen}`}
                readOnly
                className="code-input"
                onClick={(e) => e.target.select()}
              />
            </div>
          </div>
        )}
        <button
          className="button-link"
          onClick={() => (window.location.href = '/clients/whatsapp')}
        >
          Перейти к WhatsApp
        </button>
      </div>

      {/* Раздел подключения групп */}
      <div className="integration-section">
        <h3 className="section-title">Подключение групп WhatsApp и Telegram</h3>
        <p className="dashboard-text">
          Используйте следующий group token для подключения ваших групп:
        </p>
        <div className="code-block">
          <input
            type="text"
            value={groupToken}
            readOnly
            className="code-input"
            onClick={(e) => e.target.select()}
          />
        </div>
        <div className="instruction-box">
          <p>
            <strong>Инструкция для WhatsApp:</strong>
          </p>
          <ol>
            <li>Добавьте вашего WhatsApp бота через команду /start.</li>
            <li>
              Когда система запросит group token, введите вышеуказанный token.
            </li>
            <li>Группа будет привязана к вашему аккаунту.</li>
          </ol>
          <p>
            <strong>Инструкция для Telegram:</strong>
          </p>
          <ol>
            <li>Добавьте бота в вашу группу Telegram.</li>
            <li>
              При регистрации в группе укажите group token, указанный выше.
            </li>
            <li>Группа будет успешно подключена.</li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;